import { Box, Grid } from '@mui/material';
import logo from '../src/assets/logo.png';
import './App.css';
import { WhiteListBox } from './components/wl-box';
import imgGif from "../src/assets/img.gif"
function App() {
  return (
    <div className="App">
      <div className="header">
        <img src={logo} />
      </div>
      <Grid container sx={{mt:8}} spacing={3}>
        <Grid item sm={12} xs={12} md={3} lg={3}>
          <Box sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
          <img style={{height:"250px", width: "250px", borderRadius: "50%"}} src={imgGif} alt="slideshow" />
          </Box>
        </Grid>
        <Grid item  sm={12} xs={12} md={6} lg={6}>
          <WhiteListBox />
        </Grid>
        <Grid item  sm={12} xs={12} md={3} lg={3}>
        <Box sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}>
          <img style={{height:"250px", width: "250px", borderRadius: "50%"}} src={imgGif} alt="slideshow" />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default App;
