import {
  Alert,
  Box,
  Button,
  IconButton,
  Paper,
  Snackbar,
  Typography,
} from "@mui/material";
import { useWeb3React } from "@web3-react/core";
import React, { Fragment, useState, useEffect } from "react";
import { injectedConnector } from "../connectors/injected";
import { AddCircle, RemoveCircle } from "@mui/icons-material";
import { addDoc, collection, getDocs, getFirestore, updateDoc } from "firebase/firestore";
import { app } from "../connectors/firebase";

export const WhiteListBox = () => {
  const { account, activate } = useWeb3React();
  const [count, setCount] = useState(1);
  const [total, setTotal] = useState(0);
  const [showSnack, setShowSnack] = useState(false);
  const handleConnect = () => {
    activate(injectedConnector);
  };

  const [myWhitelisted, setMyWhitelisted] = useState(0);
  const [disable, setDisable] = useState(false);
  const addCount = () => {
    if (count + 1 <= 10 - myWhitelisted) {
      setCount(count + 1);
    }
  };
  const removeCount = () => {
    if (count - 1 >= 0) {
      setCount(count - 1);
    }
  };
  const get = async () => {
    const db = getFirestore(app);
    const querySnapshot = await getDocs(collection(db, "wallets-phase1"));
    let sum = 0;
    let mySum = 0;
    querySnapshot.forEach((doc) => {
      sum += Number(doc.data().items);
      if (doc.data().address.toUpperCase() === account?.toUpperCase()) {
        mySum += Number(doc.data().items);
      }
    });
    setTotal(sum);
    setMyWhitelisted(mySum);
    if(mySum === 10){
      setDisable(true)
    }else {
      setDisable(false)
    }
  };
  useEffect(() => {
    
    get();
  }, [account]);

  const whitelistNow = async () => {
    const db = getFirestore(app);
    
      await addDoc(collection(db, "wallets-phase1"), {
        address: account,
        items: Number(count),
      });
    
    setShowSnack(true);
    setCount(0);
    get();
  };

  const onSnackClose = () => {
    setShowSnack(false);
  }
  return (
    <Paper
      sx={{
        p: 4,
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "rgba(255, 255, 255, 0.3) !important",
        backdropFilter: "blur(5px) !important",
        boxShadow: "rgba(170, 170, 170, 0.55) 0px 0px 35px;",
      }}
      variant="elevation"
      elevation={5}
    >
      <Snackbar open={showSnack} autoHideDuration={6000} onClose={onSnackClose}>
        <Alert severity="success" sx={{ width: "100%" }}>
          Whitelisting Successful!
        </Alert>
      </Snackbar>
      <Typography variant="h5">
        Whitelist your wallet to be eligible for mint.
      </Typography>
      <Typography variant="h4" sx={{ mt: 3, fontWeight: 700 }}>
        {" "}
        Spots Left: {total}/3333
      </Typography>
      {account && (
        <Typography>You have whitelisted for : {myWhitelisted} NFTS</Typography>
      )}
      {account ? (
        <Fragment>
          <Typography sx={{ mt: 2 }}>
            Select the number of NFT's you plan to mint.
          </Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              mt: 2,
            }}
          >
            <IconButton onClick={removeCount} sx={{ mr: 2 }}>
              <RemoveCircle fontSize="large" />
            </IconButton>

            <Typography>{count}</Typography>
            <IconButton onClick={addCount} sx={{ ml: 2 }}>
              <AddCircle fontSize="large" />
            </IconButton>
          </Box>
          <Button disabled={disable} onClick={whitelistNow} sx={{ mt: 1 }} variant="contained" color="primary">
            Whitelist Now
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <Typography sx={{ mt: 2 }}>
            Please Connect Your Wallet to proceed.
          </Typography>
          <Button
            sx={{ mt: 1 }}
            variant="contained"
            onClick={handleConnect}
            color="primary"
          >
            Connect Wallet
          </Button>
        </Fragment>
      )}
    </Paper>
  );
};
